<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card title="Upload SeP Report">
      <asom-input-text type="file" id="fileInput" ref="fileInput" @change="onFileUpload" />
      <template #footer>
        <div class="flex justify-end flex-1">
          <asom-button text="Validate" @click="validateFile" :loading="isValidating" :disabled="isValidating || formData.file === null" />
        </div>
      </template>
    </asom-card>
    <collection-summary-validation-result 
      v-if="isValidationCompleted && !error"
      :errors="validationResults.errors"
      :isSubmitting="isSubmitting"
      @submit="onSubmit"
    >
      <div class="grid md:grid-cols-2 gap-7" v-if="validationResults.errors.length === 0">
        <asom-form-field label="Report Id">
          <p class="mt-1 font-semibold">{{validationResults.reportId}}</p>
        </asom-form-field>
        <asom-form-field label="Reporter">
          <p class="mt-1 font-semibold">{{validationResults.reporter}}</p>
        </asom-form-field>
        <asom-client-table
          class="col-span-2"
          :data="stationCashBoxTable.data"
          :columns="stationCashBoxTable.columns"
        >
          <template v-slot:removalDateTime="{data}">{{displayUtcDateTime(data)}}</template>
        </asom-client-table>
      </div>
    </collection-summary-validation-result>
  </div>
</template>
<script>
import get from 'lodash.get';
import flatten from 'lodash.flatten';
import { displayUtcDateTime } from '@/helpers/dateTimeHelpers'
import { uploadSePReport, validateSePReport } from '../../../../services/cashManagement.service';
import CollectionSummaryValidationResult from './_CollectionSummaryValidationResult.vue'
import { uploadFile } from '../../../../services/file.service';

export default {
  components: {
    CollectionSummaryValidationResult
  },
  data() {
    return {
      formData: {
        file: null,
      },
      isValidating: false,
      isSubmitting: false,
      isValidationCompleted: false,
      error: null,
      validationResults: {
        errors: [],
        reportId: null,
        reporter: null,
        stations: []
      },
    }
  },
  computed: {
    stationCashBoxTable() {
      return {
        columns: [ 'station', 'deviceId', 'vaultType', 'vaultId', 'removalDateTime', 'registeredValue', 'collectedValue', 'countedValue', 'diffValue', 'remarks'],
        data: flatten(this.validationResults.stations.map(({ stationId, stationAbbreviation, cashBoxes = []}) => cashBoxes.map(item => ({
          ...item,
          station: stationAbbreviation,
          stationId: stationId,
        }))))
      }
    },
  },
  methods: {
    displayUtcDateTime,
    onFileUpload(event) {
      this.formData.file = event.target.files[0]
    },
    async validateFile() {
      if (this.formData.file === null)
        return;
      this.isValidating = true;
      this.error = null;
      this.validationResults.errors = [];
      this.validationResults.reportId = null;
      this.validationResults.reporter = null;
      this.validationResults.stations = [];
      this.isValidationCompleted = false;
      const result = await validateSePReport([ this.formData.file ]);
      if (result.success) {
        this.validationResults.errors = get(result, 'payload.errorList', []);
        this.validationResults.reporter = get(result, 'payload.user', []);
        this.validationResults.reportId = get(result, 'payload.reportId', []);
        this.validationResults.stations = get(result, 'payload.stations', []);
      } else {
        this.error = result.payload;
      }
      
      this.isValidating = false;
      this.isValidationCompleted = true;
    },
    async onSubmit() {
      this.error = null;
      this.isValidating = true;
      this.isSubmitting = true;
      if (this.formData.file === null) {
        this.error = 'Please select a file to upload'
      } else {
        const resp1 = await uploadFile([ this.formData.file ]);
        if (resp1.success) {
          const fileId = get(resp1, 'payload.files.0.fileId');
          const resp2 = await uploadSePReport({
            attachmentId: fileId,
            transactions: this.stationCashBoxTable.data.map(item => ({
              stationId: item.stationId,
              deviceId: item.deviceId,
              vaultId: item.vaultId,
              vaultRemovalDate: item.removalDateTime,
              isCoinBox: item.vaultType === 'Coin box',
              registeredValue: item.registeredValue,
              cashCollectedValue: item.collectedValue,
              countedValue: item.countedValue,
              remarks: item.remarks
            }))
          });
          if (!resp2.success) {
            this.error = resp2.payload;
          } else {
            this.$router.push({ name : 'Cash Collection Summary Main Page' });
          }
        } else {
          this.error = resp1.payload;
        }
      }
      this.isValidating = false;
      this.isSubmitting = false;
    }
  }
}
</script>